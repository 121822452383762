<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="data-list-list-view" class="data-list-container">
    <feather-icon class="sm:inline-flex xl:hidden cursor-pointer" icon="MenuIcon" style="height: 20px; margin-right: 10px;" @click.stop="showSidebar"></feather-icon>
    <vs-table ref="table" :max-items="renderMethod.itemsPerPage" :data="users">
      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
        <div style="margin: 0 20px 10px 0;">
          <vs-radio style="margin: 0 0 0 10px;" @change="getUserList" color="success" v-model="renderMethod.paytype" vs-value="all">전체</vs-radio>
          <vs-radio style="margin: 0 0 0 10px;" @change="getUserList" color="warning" v-model="renderMethod.paytype" vs-value="free">무료</vs-radio>
          <vs-radio style="margin: 0 0 0 10px;" @change="getUserList" color="danger" v-model="renderMethod.paytype" vs-value="pay">유료</vs-radio>
        </div>
        <div id="data-list-search-rank" style="width: 80px; height: 40px; padding-top: 0;">
          <vs-dropdown class="flex flex-wrap-reverse">
            <a class="flex items-center" href="#">
              <span class="mr-2">{{ factorToText(renderMethod.factor) }}</span>
              <i class="material-icons">expand_more</i>
            </a>
            <vs-dropdown-menu>
              <vs-dropdown-item @click="renderMethod.factor='account_id'">아이디</vs-dropdown-item>
              <vs-dropdown-item @click="renderMethod.factor='name'">이름</vs-dropdown-item>
              <vs-dropdown-item @click="renderMethod.factor='email'">이메일</vs-dropdown-item>
              <vs-dropdown-item @click="renderMethod.factor='hp'">전화</vs-dropdown-item>
              <vs-dropdown-item @click="renderMethod.factor='register_date'">가입일</vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>
        <div style="margin-bottom:15px; padding-top: 0;">
          <vs-input v-on:keyup.enter="searchUserList()" class="inputx" placeholder="회원 전체 검색" v-model="renderMethod.inputText"/>
        </div>
        <div class="vs-table--search" style="margin: 0px 15px">
          <vs-button
            ref="loadableButton"
            id="button-with-loading"
            color="primary"
            type="border"
            class="p-2 cursor-pointer vs-con-loading__container"
            @click="searchUserList()"
          >검색</vs-button>
        </div>

        <!-- ITEMS PER PAGE -->
        <vs-dropdown vs-trigger-click class="cursor-pointer mr-4 mb-4">
          <div
            class="p-4 border border-solid border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
          >
            <span
              class="mr-2"
            >{{ ((currentx - 1) * renderMethod.itemsPerPage) + 1 }} - {{ numOfUsers >= currentx * renderMethod.itemsPerPage ? (currentx * renderMethod.itemsPerPage) : numOfUsers }} of {{ numOfUsers }}</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4"/>
          </div>
          <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
          <vs-dropdown-menu>
            <vs-dropdown-item @click="changeItemPerPage(4)">
              <span>4</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="changeItemPerPage(10)">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="changeItemPerPage(15)">
              <span>15</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="changeItemPerPage(20)">
              <span>20</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <template slot="thead">
        <vs-th>아이디</vs-th>
        <vs-th>이름</vs-th>
        <vs-th style="width: 70px;">만료일</vs-th>
        <vs-th>이메일</vs-th>
        <vs-th style="width: 60px;">메일링</vs-th>
        <vs-th>전화</vs-th>
        <vs-th style="max-width: 120px;">회사</vs-th>
        <vs-th style="max-width: 120px;">부서</vs-th>
        <vs-th style="max-width: 120px;">직위</vs-th>
        <vs-th style="width: 90px;">가입일</vs-th>
        <vs-th>승인</vs-th>
      </template>

      <template slot-scope="{data}">
        <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td><div v-on:click="showUserInfo(tr.mno)" style="font-weight: bold">{{ tr.id }}</div></vs-td>
            <vs-td>{{ tr.name }}</vs-td>
            <vs-td>{{ tr.e_date }}</vs-td>
            <vs-td>{{ tr.email }}</vs-td>
            <vs-td>{{ tr.mailingText }}</vs-td>
            <vs-td>{{ tr.hp }}</vs-td>
            <vs-td>{{ tr.office }}</vs-td>
            <vs-td>{{ tr.post }}</vs-td>
            <vs-td>{{ tr.position }}</vs-td>
            <vs-td>{{ tr.reg_date }}</vs-td>
            <vs-td>{{ tr.mem_ok == 1 ? 'T' : 'F' }}</vs-td>

            <!-- <vs-td>
                <vs-progress :percent="Number(tr.popularity)" :color="getPopularityColor(Number(tr.popularity))" class="shadow-md" />
              </vs-td>

              <vs-td>
                <vs-chip :color="getOrderStatusColor(tr.order_status)" class="product-order-status">{{ tr.order_status }}</vs-chip>
              </vs-td>

              <vs-td>
                <p class="product-price">${{ tr.price }}</p>
            </vs-td>-->
          </vs-tr>
        </tbody>
      </template>
    </vs-table>

    <div>
        <vs-pagination :total="numOfPages" v-model="currentx"></vs-pagination>
    </div>

     <!-- Start: 계정 수정 팝업 -->
    <vs-popup classContent="popup-example" title="계정 수정" :active.sync="editUserModalActive">
      <div class="edit-user-form">
        <div class="vx-row">
          <div class="vx-col sm:w-1/2 w-full">
            <div class="vx-row mb-6">
              <div class="vx-col sm:w-1/3 w-full" style="text-align: right;">
                <span>IP</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <span class="w-full" placeholder="없음">{{ userModalData.editUserQueryData.ip }}</span>
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col sm:w-1/3 w-full" style="text-align: right;">
                <span>이름</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" placeholder="없음" v-model="userModalData.editUserQueryData.name"/>
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col sm:w-1/3 w-full" style="text-align: right;">
                <span>아이디</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" placeholder="없음" disabled v-model="userModalData.editUserQueryData.account_id"/>
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col sm:w-1/3 w-full" style="text-align: right;">
                <span>암호</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" type="password" v-model="userModalData.editUserQueryData.password"/>
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col sm:w-1/3 w-full" style="text-align: right;">
                <span>이메일</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" placeholder="없음" type="email" v-model="userModalData.editUserQueryData.email"/>
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col sm:w-1/3 w-full" style="text-align: right;">
                <span>전화</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" placeholder="없음" v-model="userModalData.editUserQueryData.hp"/>
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col sm:w-1/3 w-full" style="text-align: right;">
                <span>회사</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" placeholder="없음" v-model="userModalData.editUserQueryData.office"/>
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col sm:w-1/3 w-full" style="text-align: right;">
                <span>부서</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" placeholder="없음" v-model="userModalData.editUserQueryData.post"/>
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col sm:w-1/3 w-full" style="text-align: right;">
                <span>직위</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" placeholder="없음" v-model="userModalData.editUserQueryData.position"/>
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col sm:w-1/3 w-full" style="text-align: right;">
                <span>승인</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <div class="select_section">
                  <v-select style="width: 150px" placeholder="승인/거부" label="text"
                    v-model="userModalData.editUserQueryData.grant" :clearable="false"
                    :options="userModalData.optionData.grantOption"/>
                </div>
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col sm:w-1/3 w-full" style="text-align: right;">
                <span>메일링</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <div v-if="renderMethod.type" class="select_section">
                  <v-select style="width: 150px" placeholder="승인/거부" label="text"
                    v-model="userModalData.editUserQueryData.mailing" :clearable="false"
                    :options="userModalData.optionData.mailingOption"/>
                </div>
                <div v-else >
                  <md-checkbox v-model="userModalData.editUserQueryData.mailing">산업</md-checkbox>
                  <md-checkbox v-model="userModalData.editUserQueryData.pemailing">화학</md-checkbox>
                  <md-checkbox v-model="userModalData.editUserQueryData.semailing">철강</md-checkbox>
                  <md-checkbox v-model="userModalData.editUserQueryData.bemailing">조선/물류</md-checkbox>
                </div>
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col sm:w-1/3 w-full" style="text-align: right;">
                <span>메일전송</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-button class="mr-3 mb-2" @click="sendMail()">전송</vs-button>
              </div>
            </div>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <h3 style="text-align: center;">입금정보</h3>
            <div class="vx-row mb-6" style="max-height: 100px; overflow-y: scroll;">
              <table>
                <div class="" :key="idx" v-for="(tr, idx) in userModalData.editUserQueryData.list">
                  <tr v-if="tr.mno" @click="changePayInfo(tr, idx)" style="text-align: center;">
                    <td style="width: 110px;">{{tr.money}}원</td>
                    <td>{{tr.s_date}}~</td>
                    <td>{{tr.e_date}}</td>
                    <td style="width: 80px;">{{tr.pay_name}}</td>
                    <td>{{tr.oksign ? tr.oksign.text : '거부'}}</td>
                  </tr>
                </div>
              </table>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col sm:w-1/3 w-full" style="text-align: right;">
                <span>입금자</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-input class="w-full" placeholder="없음" v-model="payInfo.pay_name" :disabled="payInfo.no" />
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col sm:w-1/3 w-full" style="text-align: right;">
                <span>금액</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full flex">
                <vs-input style="width: 100px;" placeholder="없음" v-model="payInfo.money" :disabled="payInfo.no" /><p style="line-height: 37px; padding: 0 0 0 10px;">원</p>
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col sm:w-1/3 w-full" style="text-align: right;">
                <span>입금일</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <datepicker type="date" v-model="payInfo.pay_date" lang="kr" style="width: 200px;" :disabled="payInfo.no" />
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col sm:w-1/3 w-full" style="text-align: right;">
                <span>기간</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <datepicker type="date" range v-model="dateRange" lang="kr" style="width: 200px;" :disabled="payInfo.no" />
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col sm:w-1/3 w-full" style="text-align: right;">
                <span>승인</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <div class="select_section">
                  <v-select style="width: 150px" placeholder="승인/거부" label="text"
                    v-model="payInfo.oksign" :clearable="false"
                    :options="userModalData.optionData.grantOption" :disabled="payInfo.no" />
                </div>
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col sm:w-1/3 w-full" style="text-align: right;">
                <span>메모</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <textarea style="height: 200px;" class="w-full" placeholder="없음" v-model="payInfo.memo" :disabled="payInfo.no" />
              </div>
            </div>
            <div class="vx-row mb-6 flex" style="justify-content: center;">
              <vs-button v-if="!payInfo.no" class="mr-3 mb-2" @click="addPayInfo()">입금정보 저장</vs-button>
              <vs-button v-else class="mr-3 mb-2" @click="newPayInfo()">입금정보 추가</vs-button>
            </div>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col sm:w-2/3 w-full ml-auto">
            <vs-button class="mr-3 mb-2" @click="editUser()">수정</vs-button>
            <vs-button
              color="warning"
              type="border"
              class="mb-2"
              @click="editUserModalInfoCancelModalActive=true"
            >취소</vs-button>
          </div>
          <div class="demo-alignment2">
            <vs-popup classContent="popup-example2" title="수정 취소" :active.sync="editUserModalInfoCancelModalActive">
              <p style="padding: 20px">취소하시겠습니까?</p>
              <div style="padding:20px; display: inline-block;">
              <vs-button @click="resetQueryDataOfUserEditModal(); editUserModalInfoCancelModalActive=false; editUserModalActive=false;" color="primary" type="filled">예</vs-button>
              </div>
              <div style="margin-bottom: 20px; display: inline-block;">
              <vs-button @click="editUserModalInfoCancelModalActive=false" color="primary" type="filled">아니오</vs-button>
              </div>
            </vs-popup>
          </div>
        </div>
      </div>
    </vs-popup>
    <!-- End: 계정 수정 팝업 -->

  </div>
</template>

<script>

import moment from 'moment';
export default {
  components: {
    
  },
  data() {
    return {
      editUserModalActive: false,
      editUserModalInfoCancelModalActive: false,
      dateRange: [new Date(), new Date()],
      userModalData: {
        editUserQueryData: {
          ip: '',
          name: '',
          target_account_id: '',
          account_id: '',
          mno: '',
          password: '',
          email: '',
          hp: '',
          office: '',
          post: '',
          position: '',
          grant: '',
          mailing: '',
          pemailing: '',
          bemailing: '',
          semailing: '',
          pay_name: '',
          money: '',
          pay_date: '',
          s_date: '',
          e_date: '',
          memo: '',
          service_period: '',
          oksign: '',
          list: [{}],
        },
        editUserQueryDataOrigin: {
          ip: '',
          name: '',
          account_id: '',
          mno: '',
          password: '',
          email: '',
          hp: '',
          office: '',
          post: '',
          position: '',
          grant: '',
          mailing: '',
          pay_name: '',
          money: '',
          pay_date: '',
          s_date: '',
          e_date: '',
          memo: '',
          service_period: '',
          oksign: '',
        },
        optionData: {
          grantOption: [
            { text: "승인", value: "1" },
            { text: "거부", value: "0" }
          ],
          mailingOption: [
            { text: "승인", value: "1" },
            { text: "거부", value: "0" }
          ]
        },
      },
      users: [],
      payInfo: {},
      currentx: 1,
      numOfUsers: 0,
      numOfPages: 0,
      searchText: "",
      renderMethod: {
        startRow: 0,
        itemsPerPage: 15,
        factor: "name",
        type: '',
        paytype: 'all',
        inputText: ""
      },
      isMounted: false,
    };
  },
  beforeRouteLeave (to, from, next) {
    this.editUserModalActive = false;
    this.editUserModalInfoCancelModalActive = false;
    setTimeout(() => { next(); }, 100);
  },
  watch: {
    '$route.query': function() {
      // console.log(this.$route.query);
      this.editUserModalActive = false;
      this.editUserModalInfoCancelModalActive = false;
      this.renderMethod.type = this.$route.query.type || ''
      this.getUserList();
      // this.created();
    },
    currentx: function() {
                                    // currentx-1 is value before changed by watch: -> currentx:
      this.renderMethod.startRow = (this.currentx-1) * this.renderMethod.itemsPerPage
      this.getUserList();
    }
  },
  computed: {
    currentPage() {
      if(this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
  },
  methods: {
    changeItemPerPage(pageNum) {
      this.renderMethod.itemsPerPage = pageNum
      this.getUserList()
    },
    
    factorToText(menu) {
      let menuText;
      switch (menu) {
        case "name":
          menuText = "이름";
          break;
        case "account_id":
          menuText = "아이디";
          break;
        case "email":
          menuText = "이메일";
          break;
        case "hp":
          menuText = "전화";
          break;
        case "register_date":
          menuText = "가입일";
          break;
      }
      return menuText;
    },

    sendMail(){
      // console.log(this.userModalData.editUserQueryData.email);
      // console.log(this.userModalData.editUserQueryData.mailing);
      // console.log(this.userModalData.editUserQueryData.pemailing);
      // console.log(this.userModalData.editUserQueryData.bemailing);
      // console.log(this.userModalData.editUserQueryData.semailing);
      let types = [];
      if(this.userModalData.editUserQueryData.mailing){ types.push('ebn'); }
      if(this.userModalData.editUserQueryData.pemailing){ types.push('chem'); }
      if(this.userModalData.editUserQueryData.bemailing){ types.push('bada'); }
      if(this.userModalData.editUserQueryData.semailing){ types.push('steel'); }
      const thisIns = this;
      const mailerOption = {
        to: this.userModalData.editUserQueryData.email,
        types
      }
      this.$http.post("/api/mailer", mailerOption).then(function(response) {
        console.log(response);
        thisIns.$vs.notify({
          title:'성공',
          text: '메일전송 성공',
          color:'success',
          iconPack: 'feather',
          icon:'icon-alert-circle'
        });
      }).catch(function(error){
        thisIns.$vs.notify({
          title: "Error",
          text: error,
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle"
        });
      });
    },

    searchUserList() {

      this.resetPagePosition()
      this.updateSearchText()
      this.getUserList()
    },
    
    getUserList() {

      this.$vs.loading({
        container: "#button-with-loading",
        scale: 0.6
      });
      const thisIns = this;
      
      this.$http
        .post("/api/manageUsers/individual/list", thisIns.buildSearchParam())
        .then(function(response) {

          thisIns.numOfUsers = response.data[0][0].user_count
          thisIns.numOfPages = Math.ceil(response.data[0][0].user_count / thisIns.renderMethod.itemsPerPage)
          thisIns.users = response.data[1]
          for(let user of thisIns.users){
            if(thisIns.renderMethod.type){
              user.mailingText = user.mailing == 1 ? 'T' : 'F';
            }else{
              let mailing = [];
              if(user.mailing == 1){ mailing.push('산업'); }
              if(user.pemailing == 1){ mailing.push('화학'); }
              if(user.bemailing == 1){ mailing.push('바다'); }
              if(user.semailing == 1){ mailing.push('철강'); }
              user.mailingText = mailing.join('/');
            }
          }

          thisIns.$vs.loading.close("#button-with-loading > .con-vs-loading");
        })
        .catch(function(error) {
          var errorText = error.toString();
          if(errorText.includes('401')){
            alert('로그인 해주세요!')
            thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
          }
          thisIns.$vs.notify({
            title: "Error",
            text: error,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle"
          });
        });
    },

    buildSearchParam() {

      var param = {};

      param['startRow'] = this.renderMethod.startRow
      param['itemsPerPage'] = this.renderMethod.itemsPerPage
      param['factor'] = this.renderMethod.factor
      param['searchText'] = this.searchText
      param['type'] = this.renderMethod.type
      param['paytype'] = this.renderMethod.paytype

      return param
    },

    resetPagePosition() {

      this.currentx = 1
    },

    updateSearchText() {
      this.searchText = this.renderMethod.inputText
    },
    
    isExistUser(account_id, func) {

      var thisObj = this
      
      thisObj.$http
        .post("/api/manageUsers/isExistUser", {account_id: account_id})
        .then(function(response) {
          
          console.log("exist response: "+response.data.exist)
          if(response.data.exist == "0") {
            
            func(false)
          }
          else if(response.data.exist == "1") {

            func(true)
          }
          else {

            thisObj.$vs.notify({
              title:'계정 생성 실패',
              text:"알 수 없는 오류로 인해 계정 생성에 실패했습니다.",
              color:'danger'})

            console.log("invalid data: "+response.data.exist)
          }
        })
        .catch(function(error) {
          var errorText = error.toString();
          if(errorText.includes('401')){
            alert('로그인 해주세요!')
            thisObj.$router.push({path: '/pages/login?to='+ thisObj.$router.history.current.path, query: thisObj.$router.history.current.query})
          }
          thisObj.$vs.notify({
            title: "Error",
            text: error,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle"
          });
        });
    },

    mapUserInfoAtEditModal(userInfo) {

      this.userModalData.editUserQueryData.ip = userInfo.ip
      this.userModalData.editUserQueryData.name = userInfo.name
      this.userModalData.editUserQueryData.account_id = userInfo.id
      this.userModalData.editUserQueryData.mno = userInfo.mno
      this.userModalData.editUserQueryData.password = ''
      this.userModalData.editUserQueryData.email = userInfo.email
      this.userModalData.editUserQueryData.hp = userInfo.hp
      this.userModalData.editUserQueryData.office = userInfo.office
      this.userModalData.editUserQueryData.post = userInfo.post
      this.userModalData.editUserQueryData.position = userInfo.position
      this.userModalData.editUserQueryData.grant = this.userModalData.optionData.grantOption.find(data => data.value == userInfo.mem_ok) || this.userModalData.optionData.grantOption[1];
      this.userModalData.editUserQueryData.pay_name = userInfo.pay_name || ''
      this.userModalData.editUserQueryData.money = userInfo.money || ''
      this.userModalData.editUserQueryData.pay_date = new Date(userInfo.pay_date)
      this.userModalData.editUserQueryData.s_date = userInfo.s_date || ''
      this.userModalData.editUserQueryData.e_date = userInfo.e_date || ''
      if(userInfo.s_date == '0000-00-00'){ userInfo.s_date = '1990-01-01'; }
      if(userInfo.e_date == '0000-00-00'){ userInfo.e_date = '1990-01-01'; }
      this.dateRange = [new Date(userInfo.s_date), new Date(userInfo.e_date)];
      this.userModalData.editUserQueryData.memo = userInfo.memo || ''
      this.userModalData.editUserQueryData.service_period = userInfo.service_period || ''
      this.userModalData.editUserQueryData.oksign = userInfo.oksign ? this.userModalData.optionData.grantOption[0] : this.userModalData.optionData.grantOption[1];
      if(this.renderMethod.type){
        this.userModalData.editUserQueryData.mailing = this.userModalData.optionData.mailingOption.find(data => data.value == userInfo.mailing) || this.userModalData.optionData.mailingOption[1];
      }
      else{
        this.userModalData.editUserQueryData.mailing = userInfo.mailing == 1 ? true : false;
        this.userModalData.editUserQueryData.pemailing = userInfo.pemailing == 1 ? true : false;
        this.userModalData.editUserQueryData.bemailing = userInfo.bemailing == 1 ? true : false;
        this.userModalData.editUserQueryData.semailing = userInfo.semailing == 1 ? true : false;
      }

      if(userInfo.list.length == 0){
        userInfo.list = [{}];
      }
      this.userModalData.editUserQueryData.list = userInfo.list;
      for(let payInfo of userInfo.list){
        payInfo.pay_date = new Date(payInfo.pay_date);
        payInfo.oksign = payInfo.oksign == 1 ? this.userModalData.optionData.grantOption[0] : this.userModalData.optionData.grantOption[1];
      }
      this.payInfo = userInfo.list[0];
      if(!this.payInfo.s_date || this.payInfo.s_date == '0000-00-00'){ this.payInfo.s_date = '0000-00-00'; }
      if(!this.payInfo.e_date || this.payInfo.e_date == '0000-00-00'){ this.payInfo.e_date = '0000-00-00'; }
      this.dateRange = [new Date(this.payInfo.s_date), new Date(this.payInfo.e_date)];
      // this.payInfo.oksign = this.payInfo.oksign == 1 ? this.userModalData.optionData.grantOption[0] : this.userModalData.optionData.grantOption[1];

      this.userModalData.editUserQueryDataOrigin.ip = userInfo.ip
      this.userModalData.editUserQueryDataOrigin.name = userInfo.name
      this.userModalData.editUserQueryDataOrigin.account_id = userInfo.id
      this.userModalData.editUserQueryDataOrigin.mno = userInfo.mno
      this.userModalData.editUserQueryDataOrigin.password = ''
      this.userModalData.editUserQueryDataOrigin.email = userInfo.email
      this.userModalData.editUserQueryDataOrigin.hp = userInfo.hp
      this.userModalData.editUserQueryDataOrigin.office = userInfo.office
      this.userModalData.editUserQueryDataOrigin.post = userInfo.post
      this.userModalData.editUserQueryDataOrigin.position = userInfo.position
      this.userModalData.editUserQueryDataOrigin.grant = this.userModalData.optionData.grantOption.find(data => data.value == userInfo.mem_ok);
      this.userModalData.editUserQueryDataOrigin.mailing = this.userModalData.optionData.mailingOption.find(data => data.value == userInfo.mailing);
      this.userModalData.editUserQueryDataOrigin.pay_name = userInfo.pay_name
      this.userModalData.editUserQueryDataOrigin.money = userInfo.money
      this.userModalData.editUserQueryDataOrigin.pay_date = userInfo.pay_date
      this.userModalData.editUserQueryDataOrigin.s_date = userInfo.s_date
      this.userModalData.editUserQueryDataOrigin.e_date = userInfo.e_date
      this.userModalData.editUserQueryDataOrigin.memo = userInfo.memo
      this.userModalData.editUserQueryDataOrigin.service_period = userInfo.service_period
      this.userModalData.editUserQueryDataOrigin.oksign = userInfo.oksign ? this.userModalData.optionData.grantOption[0] : this.userModalData.optionData.grantOption[1];
    },
// [ undefined,
//   'k402600',
//   'k402600@naver.com',
//   '010-2506-4391',
//   undefined,
//   undefined,
//   undefined,
//   undefined,
//   '',
//   'k402600' ]

    changePayInfo(tr, idx){
      console.log(tr, idx);
      this.payInfo = tr;
      this.payInfo.pay_date = new Date(this.payInfo.pay_date);
      if(!this.payInfo.s_date){ this.payInfo.s_date = '0000-00-00'; }
      if(!this.payInfo.e_date){ this.payInfo.e_date = '0000-00-00'; }
      this.dateRange = [new Date(this.payInfo.s_date), new Date(this.payInfo.e_date)];
      if(!this.payInfo.oksign){
        this.payInfo.oksign = this.userModalData.optionData.grantOption[1];
      }
      if(!this.payInfo.oksign.value){
        this.payInfo.oksign = this.payInfo.oksign == 1 ? this.userModalData.optionData.grantOption[0] : this.userModalData.optionData.grantOption[1];
      }
    },
    addPayInfo(){
      let errText = '';
      let newPayInfo = JSON.parse(JSON.stringify(this.payInfo));
      if(!newPayInfo.pay_name){
        errText = '입금자를 입력해주세요.';
      }else if(!newPayInfo.money){
        errText = '금액을 입력해주세요.';
      }else if(!newPayInfo.pay_date){
        errText = '입금일을 입력해주세요.';
      }else if(!this.dateRange[0]){
        errText = '시작일자를 입력해주세요.';
      }else if(!this.dateRange[1]){
        errText = '종료일자를 입력해주세요.';
      }else if(!newPayInfo.oksign){
        errText = '승인여부를 선택해주세요.';
      }

      if(errText){
        return this.$vs.notify({
          title: "Error",
          text: errText,
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle"
        });
      }
      newPayInfo.s_date = this.dateRange[0];
      newPayInfo.e_date = this.dateRange[1];
      newPayInfo.oksign = newPayInfo.oksign.value;
      newPayInfo.mno = this.userModalData.editUserQueryData.mno;
      // newPayInfo.pay_date = moment(newPayInfo.pay_date).format('YYYY-MM-DD');
      console.log(this.payInfo);
      const thisObj = this;
      this.$http
        .post("/api/manageUsers/individual/new_pay_info", newPayInfo)
        .then(function(response) {
          if(response.data && response.data.state == 0){
            newPayInfo.s_date = moment(newPayInfo.s_date).format('YYYY-MM-DD');
            newPayInfo.e_date = moment(newPayInfo.e_date).format('YYYY-MM-DD');
            newPayInfo.oksign = newPayInfo.oksign == 1 ? thisObj.userModalData.optionData.grantOption[0] : thisObj.userModalData.optionData.grantOption[1];
            thisObj.userModalData.editUserQueryData.list = [newPayInfo].concat(thisObj.userModalData.editUserQueryData.list);
          }else{
            throw Error('send fail');
          }
        })
        .catch(function(error) {
          var errorText = error.toString();
          if(errorText.includes('401')){
            alert('로그인 해주세요!')
            console.log(thisObj.$router)
            thisObj.$router.push({path: '/pages/login?to='+ thisObj.$router.history.current.path, query: thisObj.$router.history.current.query})
          }
          thisObj.$vs.notify({
            title: "Error",
            text: error,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle"
          });
        });
    },
    newPayInfo(){
      this.payInfo = {mno: this.userModalData.editUserQueryData.mno};
      this.dateRange = [null, null];
    },
    showUserInfo(mno) {

      var thisObj = this;

      thisObj.$http
          .post("/api/manageUsers/individual/get", {mno, type: this.renderMethod.type})
          .then(function(response) {

            console.log(response.data)
            thisObj.mapUserInfoAtEditModal(response.data)
            thisObj.editUserModalActive = true
          })
          .catch(function(error) {
            var errorText = error.toString();
            if(errorText.includes('401')){
              alert('로그인 해주세요!')
              console.log(thisObj.$router)
              thisObj.$router.push({path: '/pages/login?to='+ thisObj.$router.history.current.path, query: thisObj.$router.history.current.query})
            }
            thisObj.$vs.notify({
              title: "Error",
              text: error,
              color: "danger",
              iconPack: "feather",
              icon: "icon-alert-circle"
            });
          });
    },

    editUser() {

      var thisObj = this

      if(!thisObj.isPassedIntegrityVerification()) {
        return;
      }

      // if account id edited.
      if(thisObj.userModalData.editUserQueryDataOrigin.account_id != thisObj.userModalData.editUserQueryData.account_id) {

        thisObj.isExistUser(thisObj.userModalData.editUserQueryData.account_id, function(exist) {

          if(exist) {
            
            thisObj.$vs.notify({
              title:'중복 아이디',
              text:"중복된 아이디입니다.",
              color:'warning'})

            return
          }

          thisObj.runEditUser()
        })
      }
      else {
          thisObj.runEditUser()
      }
    },

    runEditUser() {

      var thisObj = this

      thisObj.userModalData.editUserQueryData.target_account_id = thisObj.userModalData.editUserQueryDataOrigin.account_id;
      thisObj.userModalData.editUserQueryData.grant = thisObj.userModalData.editUserQueryData.grant.value;
      thisObj.userModalData.editUserQueryData.oksign = thisObj.userModalData.editUserQueryData.oksign.value || 0;
      thisObj.userModalData.editUserQueryData.type = thisObj.renderMethod.type;
      thisObj.userModalData.editUserQueryData.s_date = moment(thisObj.dateRange[0]).format('YYYY-MM-DD');
      thisObj.userModalData.editUserQueryData.e_date = moment(thisObj.dateRange[1]).format('YYYY-MM-DD');
      thisObj.userModalData.editUserQueryData.pay_date = moment(thisObj.userModalData.editUserQueryData.pay_date).format('YYYY-MM-DD');

      if(this.renderMethod.type){
        thisObj.userModalData.editUserQueryData.mailing = thisObj.userModalData.editUserQueryData.mailing.value;
      }else{
        thisObj.userModalData.editUserQueryData.mailing = thisObj.userModalData.editUserQueryData.mailing ? 1 : 0;
        thisObj.userModalData.editUserQueryData.pemailing = thisObj.userModalData.editUserQueryData.pemailing ? 1 : 0;
        thisObj.userModalData.editUserQueryData.bemailing = thisObj.userModalData.editUserQueryData.bemailing ? 1 : 0;
        thisObj.userModalData.editUserQueryData.semailing = thisObj.userModalData.editUserQueryData.semailing ? 1 : 0;
      }
      thisObj.$http
          .post("/api/manageUsers/individual/edit", thisObj.userModalData.editUserQueryData)
          .then(function(response) {
            
            if(response.data === "success") {

              thisObj.$vs.notify({
                title:'계정 수정 완료',
                text:"계정 '"+thisObj.userModalData.editUserQueryDataOrigin.account_id+"' 수정 완료.",
                color:'success'})
              
              thisObj.editUserModalActive = false
              thisObj.resetQueryDataOfUserEditModal()
              thisObj.getUserList()
            }
            else {
              thisObj.$vs.notify({
                title:'계정 수정 실패',
                text:"계정 '"+thisObj.userModalData.editUserQueryDataOrigin.account_id+"' 수정 실패.",
                color:'danger'})
            }

            thisObj.$vs.loading.close("#button-with-loading > .con-vs-loading");
          })
          .catch(function(error) {
            var errorText = error.toString();
            if(errorText.includes('401')){
              alert('로그인 해주세요!')
              console.log(thisObj.$router)
              thisObj.$router.push({path: '/pages/login?to='+ thisObj.$router.history.current.path, query: thisObj.$router.history.current.query})
            }
            thisObj.$vs.notify({
              title: "Error",
              text: error,
              color: "danger",
              iconPack: "feather",
              icon: "icon-alert-circle"
            });
          });
    },

    resetQueryDataOfUserEditModal() {
      this.userModalData.editUserQueryData.name
      = this.userModalData.editUserQueryData.mno
      = this.userModalData.editUserQueryData.account_id
      = this.userModalData.editUserQueryData.password
      = this.userModalData.editUserQueryData.email
      = this.userModalData.editUserQueryData.hp
      = this.userModalData.editUserQueryData.tailname
      = this.userModalData.editUserQueryData.department
      = this.userModalData.editUserQueryData.level
      = this.userModalData.editUserQueryData.showWriterList
      = this.userModalData.editUserQueryData.showStatistics
      = this.userModalData.editUserQueryData.grant
      = ''
    },

    isPassedIntegrityVerification() {
      var nameChanged = this.userModalData.editUserQueryData.name !== this.userModalData.editUserQueryDataOrigin.name
      var accountIdChanged = this.userModalData.editUserQueryData.account_id !== this.userModalData.editUserQueryDataOrigin.account_id
      var passwordChanged = this.userModalData.editUserQueryData.password !== this.userModalData.editUserQueryDataOrigin.password
      var emailChanged = this.userModalData.editUserQueryData.email !== this.userModalData.editUserQueryDataOrigin.email
      var hpChanged = this.userModalData.editUserQueryData.hp !== this.userModalData.editUserQueryDataOrigin.hp
      var grantChanged = this.userModalData.editUserQueryData.grant !== this.userModalData.editUserQueryDataOrigin.grant

      var fillDataCheck = (nameChanged ? (this.userModalData.editUserQueryData.name && this.userModalData.editUserQueryDataOrigin.name != '') : true)
              && (accountIdChanged ? (this.userModalData.editUserQueryData.account_id && this.userModalData.editUserQueryDataOrigin.account_id != '') : true)
              && (emailChanged ? (this.userModalData.editUserQueryData.email && this.userModalData.editUserQueryDataOrigin.email != '') : true)
              && (hpChanged ? (this.userModalData.editUserQueryData.hp && this.userModalData.editUserQueryDataOrigin.hp != '') : true)
              && (grantChanged ? (this.userModalData.editUserQueryData.grant && this.userModalData.editUserQueryDataOrigin.grant != '') : true)

      var regExpname = /[0-9]|[a-z]|[A-Z]|[가-힣]/;
      var nameCheck = (this.userModalData.editUserQueryData.name.length >= 2 && this.userModalData.editUserQueryData.name.length <= 12) 
                        && (nameChanged ? regExpname.test(this.userModalData.editUserQueryData.name) : true)

      var regExpAccountId = /^[a-zA-Z0-9]{6,12}$/
      var accountIdCheck = accountIdChanged ? regExpAccountId.test(this.userModalData.editUserQueryData.account_id) : true

      var regExpPassword = /^(?=.*[a-zA-Z])((?=.*\d)|(?=.*\W)).{6,20}$/
      var passwordCheck = passwordChanged ? regExpPassword.test(this.userModalData.editUserQueryData.password) : true

      var regExpEmail = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i
      var emailCheck = emailChanged ? regExpEmail.test(this.userModalData.editUserQueryData.email) : true

      var regExpHp = /^\d{3}-\d{4}-\d{4}$/
      var hpCheck = hpChanged ? regExpHp.test(this.userModalData.editUserQueryData.hp) : true
      
      if(!fillDataCheck) {

        this.$vs.notify({
          title:'미입력',
          text:"빠짐없이 입력 하세요.",
          color:'warning'})

          return false;
      }
      
      if(!nameCheck) {

        this.$vs.notify({
          title:'닉네임',
          text:"닉네임은 6~12 글자, 공백 및 특수문자를 포함할 수 없습니다.",
          color:'warning'})
      }
      if(!accountIdCheck) {

        this.$vs.notify({
          title:'아이디',
          text:"아이디는 6~12 글자, 공백 및 특수문자를 포함할 수 없습니다.",
          color:'warning'})
      }
      if(!passwordCheck) {

        this.$vs.notify({
          title:'비밀번호',
          text:"비밀번호는 8~15개의 영문 대소문자, 1개 이상의 특수문자를 반드시 포함해야 합니다.",
          color:'warning'})
      }
      if(!emailCheck) {

        this.$vs.notify({
          title:'이메일',
          text:"올바른 형식의 이메일을 입력해주세요.",
          color:'warning'})
      }
      if(!hpCheck) {

        this.$vs.notify({
          title:'전화번호',
          text:"올바른 형식의 전화번호를 입력해주세요.",
          color:'warning'})
      }

      return nameCheck && accountIdCheck && passwordCheck && emailCheck && hpCheck
    },
    created() {
      
      const thisIns = this;
      // if(this.$route.query.type == 'bada' || this.$route.query.type == 'chem' || this.$route.query.type == 'steel'){
        this.renderMethod.type = this.$route.query.type || ''
      // }
      this.$http
        .post("/api/manageUsers/individual/list", thisIns.renderMethod)
        .then(function(response) {
          
          thisIns.numOfUsers = response.data[0][0].user_count
          thisIns.numOfPages = parseInt(response.data[0][0].user_count / thisIns.renderMethod.itemsPerPage)
          thisIns.users = response.data[1]
        })
        .catch(function(error) {
          var errorText = error.toString();
          if(errorText.includes('401')){
            alert('로그인 해주세요!')
            console.log(thisIns.$router)
            thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
          }
          thisIns.$vs.notify({
            title: "Error",
            text: error,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle"
          });
        });
    },
    showSidebar() {
        this.$store.commit('TOGGLE_IS_SIDEBAR_ACTIVE', true);
    },
  },

  created() {
    // this.created();
  },
  mounted() {
    this.renderMethod.type = this.$route.query.type || ''
    this.getUserList();
    this.isMounted = true;
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/custom-style/vs-table-common.scss";
</style>